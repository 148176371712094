import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Fade from "react-reveal/Fade";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Accolades from "../components/Repeating/Accolades";
import Testimonials from "../components/Repeating/Testimonials";
import Attorneys from "../components/Repeating/Attorneys";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ModalVideo from "../components/Modal/ModalVideo";
import SliderProgressBar from "../components/Slider/SliderProgressBar";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithUnderline from "../components/Button/ButtonWithUnderline";

const Page = ({ data }) => {
   const sliderContent = [
      {
         image: data.business.childImageSharp.gatsbyImageData,
         title: "Business",
         href: "/business/",
      },
      {
         image: data.construction.childImageSharp.gatsbyImageData,
         title: "Construction",
         href: "/construction/",
      },
      {
         image: data.criminalDefense.childImageSharp.gatsbyImageData,
         title: "Criminal Defense",
         href: "/criminal-defense/",
      },
      {
         image: data.DUIDWAI.childImageSharp.gatsbyImageData,
         title: "DUI / DWAI",
         href: "/dui-dwai/",
      },
      {
         image: data.elderLaw.childImageSharp.gatsbyImageData,
         title: "Elder Law",
         href: "/elder-law/",
      },
      {
         image: data.estatePlanning.childImageSharp.gatsbyImageData,
         title: "Estate Planning",
         href: "/estate-planning/",
      },
      {
         image: data.familyLaw.childImageSharp.gatsbyImageData,
         title: "Family Law",
         href: "/family-law/",
      },
      {
         image: data.guardianshipConservatorship.childImageSharp.gatsbyImageData,
         title: "Guardianship & Conservatorship",
         href: "/estate-planning/guardianship-conservatorship/",
      },
      {
         image: data.medicaid.childImageSharp.gatsbyImageData,
         title: "Medicaid",
         href: "/long-term-elder-care-planning/",
      },
      {
         image: data.personalInjury.childImageSharp.gatsbyImageData,
         title: "Personal Injury",
         href: "/personal-injury/",
      },
      {
         image: data.probate.childImageSharp.gatsbyImageData,
         title: "Probate",
         href: "/probate/",
      },
      {
         image: data.realEstate.childImageSharp.gatsbyImageData,
         title: "Real Estate",
         href: "/real-estate/",
      },
   ];

   const [autoPlay, setAutoPlay] = useState(false);

   const handlePlayVideo = () => {
      setAutoPlay(true);
   };

   return (
      <Layout headerStyle="overlap">
         <SearchEngineOptimization
            title="Colorado Lawyers | Full-Service Law Firm Denver | JBP"
            description="Jorgensen, Brownell & Pepin, P.C. is a top full-service law firm, serving Colorado from the Denver Metro Area since 1989. Get a consultation today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <section className="relative pt-36 md:pt-44 lg:md:pt-60 pb-5 md:pb-48 lg:pb-64 md:text-center overflow-hidden">
            <div className="absolute top-0 left-0 w-full h-full">
               <GatsbyImage image={data.heroDesktop.childImageSharp.gatsbyImageData} className="h-full w-full" />
            </div>

            {/* <div className="absolute top-0 left-0 w-full h-full md:hidden">
          <GatsbyImage
            image={data.heroMobile.childImageSharp.gatsbyImageData}
            className="h-full"
          />
        </div> */}

            <div className="container relative">
               <div className="font-heading font-bold text-secondary-50 text-mobile-7xl md:text-7xl mb-6">Miles above the competition.</div>
               <div className="font-normal text-primary-50 text-2xl mb-6">Bringing Colorado exceptional legal service since 1989.</div>
               <button
                  data-modal-open="modal-video"
                  onClick={handlePlayVideo}
                  className="relative flex items-center md:justify-center w-full mb-22 md:mb-0"
               >
                  <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-3.5">
                     <path
                        d="M17.5 0.705383C7.83594 0.705383 0 8.54132 0 18.2054C0 27.8694 7.83594 35.7054 17.5 35.7054C27.1641 35.7054 35 27.8694 35 18.2054C35 8.54132 27.1641 0.705383 17.5 0.705383ZM23.1289 18.4749L14.5977 24.6819C14.5509 24.7156 14.4958 24.7356 14.4383 24.7399C14.3809 24.7443 14.3234 24.7327 14.2721 24.7064C14.2208 24.6802 14.1778 24.6403 14.1477 24.5912C14.1176 24.5421 14.1017 24.4856 14.1016 24.428V12.0218C14.1014 11.9641 14.1172 11.9074 14.1472 11.8582C14.1772 11.8089 14.2203 11.7689 14.2717 11.7426C14.3231 11.7163 14.3807 11.7047 14.4382 11.7092C14.4958 11.7137 14.551 11.734 14.5977 11.7679L23.1289 17.971C23.1692 17.9995 23.2021 18.0373 23.2248 18.0811C23.2475 18.125 23.2593 18.1736 23.2593 18.223C23.2593 18.2723 23.2475 18.321 23.2248 18.3648C23.2021 18.4087 23.1692 18.4464 23.1289 18.4749Z"
                        fill="#D58B45"
                     />
                  </svg>
                  <span className="font-heading font-bold text-xl text-white md:text-primary-600">Play Video</span>
               </button>

               <ButtonSolid href="tel:720-491-3117" text="(720) 491-3117" className="w-full md:w-auto md:hidden" />
            </div>
         </section>

         <div className="relative overflow-hidden">
            <img src={data.lineBackground.publicURL} alt="Curved Line Background" className="hidden md:block absolute w-full left-0 top-0" />
            <section className="pt-18 md:pt-28 pb-18 md:pb-28">
               <div className="container">
                  <div className="grid md:grid-cols-2 gap-y-10 items-center">
                     <div>
                        <h1>Your Full-Service Law Firm In Greater Denver & Northern Colorado</h1>
                        <div className="md:pl-8 lg:pl-14 md:pt-3.5">
                           <p>
                              At Jorgensen, Brownell & Pepin, P.C., we obtain targeted outcomes for individuals, families, and businesses alike. As a
                              full-service law firm with a track record of success and decades of experience behind us, we are known as a team of
                              Colorado lawyers capable of achieving great things both in and out of the courtroom. We utilize our years of experience
                              and tradition of excellence to help our clients succeed where others cannot.
                           </p>
                           <p>
                              Our legal service goes beyond representation. We educate our clients about their cases and the law. We want them to
                              understand the risks and rewards of their choices. We help clients reach solutions tailored to their unique needs and
                              goals through strategic problem solving and sound advice. Regardless of your specific situation, your best interests
                              will be our primary concern when we represent you.
                           </p>
                           <ButtonSolid modal="modal-contact" text="Contact Us" className="w-full md:w-auto" />
                        </div>
                     </div>
                     <div>
                        <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} />
                     </div>
                  </div>
               </div>
            </section>

            <Accolades className="relative" />

            <section className="bg-black/80 pt-20 md:pt-32 pb-20 md:pb-32">
               <div className="container">
                  <div className="flex items-start justify-between mb-8">
                     <div className="max-w-[800px]">
                        <h2>Our Legal Services</h2>
                        <div className="md:pl-10 lg:pl-20 md:pt-3.5">
                           <p>
                              Life can surprise you at any moment, and not always for the better. When you get caught off-guard by a legal dispute
                              that threatens your wellbeing, finances, business, or reputation, know that our attorneys are at-the-ready to step in
                              and take over for you. We assist with a variety of case types, ranging from family law and personal injury to criminal
                              defense and business law. Whatever life puts in front of you, we are here to help manage it.
                           </p>
                        </div>
                     </div>

                     <ButtonWithUnderline href="/practice-areas/" text="All Practice Areas" className="w-full md:w-auto hidden md:inline-flex" />
                  </div>

                  <SliderProgressBar slideCount={sliderContent.length}>
                     {sliderContent.map((item, i) => (
                        <div key={i}>
                           <AniLink fade to={item.href} className="group no-underline">
                              <div className="overflow-hidden mb-3.5">
                                 <GatsbyImage
                                    image={item.image}
                                    className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                                 />
                              </div>
                              <div className="font-heading text-secondary-50 text-lg mb-0">{item.title}</div>
                           </AniLink>
                        </div>
                     ))}
                  </SliderProgressBar>

                  <div className="md:hidden mt-10">
                     <ButtonWithUnderline href="/practice-areas/" text="All Practice Areas" className="w-full md:w-auto" />
                  </div>
               </div>
            </section>
         </div>

         <section className="pt-20 md:pt-28 pb-20 md:pb-28 lg:pt-[10rem]">
            <div className="container">
               <h2 className="mx-auto text-center lg:text-left">Committed To Your Success</h2>
               <div className="grid lg:grid-cols-2 gap-x-8 gap-y-8 pb-26">
                  <div>
                     <header className="md:mb-14  mx-auto text-center lg:text-left max-w-[550px]">
                        <p className="md:font-normal md:text-xl">
                           Our dedicated attorneys have decades of experience and are ready to fight for you and your case.
                        </p>
                     </header>

                     <div className="lg:hidden -top-[85px] md:-top-[100px] mx-auto relative max-w-[538px] min-h-[508px] mb-8 xs:mb-32">
                        <Fade right duration="1750" delay="550" distance="70px" fraction={0.2}>
                           <div className="absolute right-0 top-[85px] md:top-[130px] ml-6 z-10">
                              <GatsbyImage image={data.teamImageTop.childImageSharp.gatsbyImageData} className="z-10 max-w-[460px] shadow-4xl" />
                           </div>
                        </Fade>
                        <Fade left duration="2000" distance="40px" fraction={0.17}>
                           <div className="absolute left-0 -bottom-[40px] md:-bottom-[90px] lg:bottom-[0px] z-10 ">
                              <GatsbyImage image={data.teamImageBottom.childImageSharp.gatsbyImageData} className="z-10 max-w-[450px] shadow-4xl " />
                           </div>
                        </Fade>
                        <Fade center duration="1250" delay="1250" distance="50px" fraction={0.2}>
                           <div className="absolute  bottom-0 xs:-bottom-[7rem] z-0">
                              <img src={data.teamImageBg.publicURL} className=" max-w-[516px] z-0" />
                           </div>
                        </Fade> 

                        {/* <Fade center duration="2000" distance="40px" fraction={0.2}>
                           <div className="absolute  bottom-0 xs:-bottom-[7rem]">
                              <GatsbyImage image={data.teamImage1.childImageSharp.gatsbyImageData} className="z-0  max-w-[516px]" />
                           </div>
                        </Fade> */}
                     </div>

                     <div className="mx-auto lg:mx-[inherit] flex flex-col md:flex-row items-start space-y-5 md:space-y-0 space-y md:space-x-10 lg:space-x-16 max-w-[460px] mb-12 md:mb-18">
                        <img src={data.one.publicURL} alt="One" />
                        <div>
                           <p className="font-heading font-bold text-secondary-50 text-xl mb-3.5">We know your rights.</p>
                           <p className="mb-0">We’re a full-service law firm with over 30 years of experience.</p>
                        </div>
                     </div>

                     <div className="mx-auto lg:mx-[inherit] flex flex-col md:flex-row items-start space-y-5 md:space-y-0 md:space-x-8 lg:space-x-14 max-w-[460px] mb-12 md:mb-18">
                        <img src={data.two.publicURL} alt="Two" />
                        <div>
                           <p className="font-heading font-bold text-secondary-50 text-xl mb-3.5">We will fight to protect them.</p>
                           <p className="mb-0">We’re dedicated to your case and committed to the Colorado community.</p>
                        </div>
                     </div>

                     <div className="mx-auto lg:mx-[inherit] flex flex-col md:flex-row items-start space-y-5 md:space-y-0 md:space-x-8 lg:space-x-14 max-w-[460px]">
                        <img src={data.three.publicURL} alt="Three" />
                        <div>
                           <p className="font-heading font-bold text-secondary-50 text-xl mb-3.5">We are here when you need it most.</p>
                           <p className="mb-0">We approach every case, and every one of our clients, with compassion.</p>
                        </div>
                     </div>
                  </div>
                  {/* DESKTOP LAYOUT */}
                  <div className="hidden lg:block relative max-h-[585px]">
                     <Fade right duration="1750" delay="550" distance="70px" fraction={0.2}>
                        <div className="absolute right-0   top-0 md:top-[0] top-[85px] lg:top-[85px] ml-6 z-10">
                           <GatsbyImage image={data.teamImageTop.childImageSharp.gatsbyImageData} className="z-10 max-w-[460px] shadow-4xl" />
                        </div>
                     </Fade>
                     <Fade left duration="2000" distance="40px" fraction={0.17}>
                        <div className="absolute left-0 -bottom-[50px] md:-bottom-[90px] lg:bottom-[0px] z-10 ">
                           <GatsbyImage image={data.teamImageBottom.childImageSharp.gatsbyImageData} className="z-10 max-w-[450px] shadow-4xl " />
                        </div>
                     </Fade>
                     <Fade center duration="1250" delay="1250" distance="50px" fraction={0.2}>
                        <div className="absolute  bottom-0 xs:-bottom-[7rem] z-0">
                           <img src={data.teamImageBg.publicURL} className=" max-w-[516px] z-0" />
                        </div>
                     </Fade>
                  </div>
                  {/* DESKTOP LAYOUT END OF */}

               </div>
            </div>
         </section>

         <div
            style={{
               background: "linear-gradient(5.55deg, #26272B 16.02%, #838A9D 87.03%)",
            }}
         >
            <Testimonials />
            <Attorneys />
         </div>
         <RecentBlogPosts />
         <CallToAction />
         <ModalVideo
            autoPlay={autoPlay}
            videoSource="https://player.vimeo.com/external/659082300.hd.mp4?s=6dd5f938c63496ec4517f4331af55180ed4024e7&profile_id=175"
         />
      </Layout>
   );
};

export const data = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/homepage.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Homepage.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "1.0 Homepage/1.0 Full Width Hero.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1440)
         }
      }
      heroMobile: file(relativePath: { eq: "1.0 Homepage/1.0-Full-Width-Hero-mobile.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
         }
      }
      intro: file(relativePath: { eq: "1.0 Homepage/2.0 Jbp round.png" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 598)
         }
      }
      teamImage1: file(relativePath: { eq: "Our Team/firm/top_bottom.png" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 515)
         }
      }      
      teamImageTop: file(relativePath: { eq: "Our Team/firm/Group top.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 515)
         }
      }
      teamImageBottom: file(relativePath: { eq: "Our Team/firm/Group bottom.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 515)
         }
      }      
      teamImageBg: file(relativePath: { eq: "Our Team/firm/background graphic.svg" }) {
         publicURL
      }
      business: file(relativePath: { eq: "2.0 Services Hub/1.0 Business.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      construction: file(relativePath: { eq: "2.0 Services Hub/2.0 Construction.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      criminalDefense: file(relativePath: { eq: "2.0 Services Hub/4.0 Criminal Defense.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      DUIDWAI: file(relativePath: { eq: "2.0 Services Hub/5.0 DUI-DWAI.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      elderLaw: file(relativePath: { eq: "2.0 Services Hub/6.0 Elder Law.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      estatePlanning: file(relativePath: { eq: "2.0 Services Hub/7.0 Estate Planning.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      familyLaw: file(relativePath: { eq: "2.0 Services Hub/8.0 Family Law.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      guardianshipConservatorship: file(relativePath: { eq: "2.0 Services Hub/9.0 Guardianship _ Conservatorship.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      intellectualProperty: file(relativePath: { eq: "2.0 Services Hub/10.0 Intellectual Property.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      medicaid: file(relativePath: { eq: "2.0 Services Hub/11.0 Medicaid.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      personalInjury: file(relativePath: { eq: "2.0 Services Hub/12.0 Personal Injury.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      probate: file(relativePath: { eq: "2.0 Services Hub/13 Probate.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      realEstate: file(relativePath: { eq: "2.0 Services Hub/14 Real Estate.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 564)
         }
      }
      lineBackground: file(relativePath: { eq: "1.0 Homepage/2.1 Background.svg" }) {
         publicURL
      }
      one: file(relativePath: { eq: "1.0 Homepage/1.svg" }) {
         publicURL
      }
      two: file(relativePath: { eq: "1.0 Homepage/2.svg" }) {
         publicURL
      }
      three: file(relativePath: { eq: "1.0 Homepage/3.svg" }) {
         publicURL
      }
   }
`;

export default Page;
